@font-face {
  font-family: 'Roboto Mono';
  font-weight: 700;
  font-style: normal;
  src: url(./fonts/RobotoMono/RobotoMono-Bold.ttf);
  font-display: swap;
}
@font-face {
  font-family: 'Roboto Mono';
  font-weight: 200;
  font-style: normal;
  src: url(./fonts/RobotoMono/RobotoMono-ExtraLight.ttf);
  font-display: swap;
}
@font-face {
  font-family: 'Roboto Mono';
  font-weight: 300;
  font-style: normal;
  src: url(./fonts/RobotoMono/RobotoMono-Light.ttf);
  font-display: swap;
}
@font-face {
  font-family: 'Roboto Mono';
  font-weight: 400;
  font-style: normal;
  src: url(./fonts/RobotoMono/RobotoMono-Regular.ttf);
  font-display: swap;
}
@font-face {
  font-family: 'Roboto Mono';
  font-weight: 600;
  font-style: normal;
  src: url(./fonts/RobotoMono/RobotoMono-SemiBold.ttf);
  font-display: swap;
}
@font-face {
  font-family: 'Russo One';
  font-style: normal;
  src: url(./fonts/RussoOne/RussoOne-Regular.ttf);
  font-display: swap;
}

html, body {
  /*font-family: 'Montserrat Alternates', sans-serif !important;*/
  font-family: 'Roboto Mono', monospace !important;
  font-size: 15px;
}
.styles-module_message__2RbY_{
  max-width: calc(100% - 100px) !important;
}
.carousel-control-next-icon {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAACXBIWXMAAAsTAAALEwEAmpwYAAAArUlEQVR4nNXWPQrCQBCG4fcEsQkJ6oY0ksaT5ZaxE2wVf9HKFF7CIKwgsulmPnRg6geGb2cWfqQmwArYAZUXkgFr4Bn74oW1H8i7z0CwhvI4shQ2t8aKEezkgZXAfgSbWWMhhuEbO3pgr8RdVVgN3BLYAZhaYwvgrsIaoFdhS+CRwDZ/CTWK0UnCUCviXSkebFCsoFKxVAvFmchVh69VnfJM9Tkhfrc6YOuJmNYA6EaADfD8J8gAAAAASUVORK5CYII=) !important;
}

.carousel-control-prev-icon {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAs0lEQVR4nNXWPQoCMRCG4fcEWsgq+AOKlXowj6mlhZ2uhWujNuolVhYiLJIUwpcPHJj6IZNkZuBPYgKcgC3QzYlcgDrkDuiokTFQtZBPrpXIKIGUQE+FDMKd1F95BoZKpIwgVThlVuQKTFVIP4HcgJkSOUaQOzBXIocI8gAWKqRIIE9gqUKa2EeQF7BSIlaocJXO9hisz9v6Ya0tyNpUrWPCOviso9y6nNDCmpJtcq5bP8UbhdWADWvaLVIAAAAASUVORK5CYII=) !important;
}

.hide {
  display: none !important;
}
.txt-right {
  text-align: right;
}
.txt-left {
  text-align: left;
}
.txt-center {
  text-align: center;
}
.w-100 {
  width: 100% !important;
  height: auto;
}
.mr-10 {
  margin-right: 10px;
}
.ml-10 {
  margin-left: 10px;
}
.col-md-6-height {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  display: flex;
}
.btn-close {
  background: transparent url(./img/close.png) !important;
  background-repeat: no-repeat;
  background-size: cover !important;
  opacity: 1 !important;
}
.form-floating>.form-control-plaintext~label, .form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label {
  transform: scale(.75) translateY(-0.5rem) translateX(0.1rem) !important;
}
.form-control {
  border: 0 !important;
  border-bottom: 1px solid #ffffff !important;
  border-radius: 0 !important;
}
.form-floating>label {
  padding: .5rem .75rem !important;
  font-size: 14px !important;
  color: #3bffff !important;
}
.form-floating>.form-control {
  color: #fff !important; 
}
.form-floating>.form-control, .form-floating>.form-control-plaintext, .form-floating>.form-select {
  min-height: 35px !important;
  max-height: 85px !important;
  background: transparent !important;
}
.btn {
  border-radius: 50px !important;
  color: #fff !important;
  padding:16px 31px !important;
  font-family: 'Roboto Mono', monospace !important;
  font-size: 17px !important;
  font-weight: 700;
}
.btn-outline-primary {
  --bs-btn-color: #ffaa22 !important;
  --bs-btn-border-color: #ffaa22 !important;
  --bs-btn-hover-bg: #ffaa22 !important;
  --bs-btn-hover-border-color: #ffaa22 !important;
  --bs-btn-active-bg: #ffaa22 !important;
  --bs-btn-active-border-color: #ffaa22 !important;
  --bs-btn-disabled-color: #ffaa22 !important;
}
.particles-bg-canvas-self {
  background-color: #f9fafe;
  border-radius: 50px 50px 0 0;
}

.navbar {
  max-width: 100% !important;
  padding: 20px 0 !important;
}
.nav-link {
  /*color: #fff !important;*/
  font-size: 15px !important;
  padding-right: 18px !important;
  padding-left: 18px !important;
  cursor: pointer;
}
.navspace {
  height: 85px;
}
.nav-icons {
  color: #fff; 
  font-size: 20px; 
  cursor: pointer;
  top: 5px;
  position: relative;
}
.nav-link-custom {
  color: #fff;
  cursor: pointer;
}
.logo {
  height: 30px;
  cursor: pointer;
}
.nav-link-icons {
  padding-right: 0px !important;
  padding-left: 10px !important;
}
.info-bar {
  background-color: #000;
  height: 35px;
  width: 100%;
  position: fixed;
  z-index: 4;
  padding: 5px 0;
}

.btn_action {
	background-color:#ffec64;
	border-radius:28px;
	border:1px solid #ffaa22;
	display:inline-block;
	cursor:pointer;
	color:#333333;
	font-family:Arial;
	font-size:17px;
  font-weight: 700;
	padding:16px 31px;
	text-decoration:none;
	text-shadow:0px 1px 0px #ffee66;
  font-family: 'Roboto Mono', monospace !important;
}
.btn_action:hover {
	background-color:#ffab23;
}
.btn_action:active {
	position:relative;
	top:1px;
}

.btn-contact {
  background: rgb(252,176,69,1) !important;
  /*background: linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);*/
}
.brand-image {
  width: auto;
  height: 40px;
  cursor: pointer;
}
.brand-image-start {
  width: auto;
  height: 30px;
  cursor: pointer;
}
.nav-bar-custom {
  background-color: #fff;
  box-shadow: 0px 10px 12px 0px rgba(0,0,0,0.14);
  padding: 10px 0 !important;
}
.nav-bar-custom-start {
  background-color: transparent;
  /*background-color: transparent !important;
  top: 35px !important;*/
}

.styled-img {
  /*width: 368px;
  height: 200px;*/
  width: 130px;
  height: auto;
  border-radius: 4px;
  /*box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.12);*/
  object-fit: cover;
  object-position: top;
}
.home-container {
  position: relative;
  top: 85px;
}
.home-title {
  font-size: 25px;
  text-align: right;
  /*font-family: 'Montserrat', sans-serif;*/
  font-family: 'Roboto Mono', monospace;
  color: #fff;
  padding-top: 60px;
}
.home-title-big {
  font-family: 'Russo One';
  line-height: 130px;
  font-weight: 600;
  font-size: 114px;
  color: #fff;
  text-align: right;
}
.home-subtitle {
  font-family: 'Roboto Mono', monospace;
  font-size: 16px;
  text-align: right;
  color: #fff;
}
.home-bg {
  background: #fff;
  /*background-image: url(./img/galaxy_d.jpg);
  background-size: cover;
  background-position: bottom;
  position: absolute;
  top: 0;
  height: 750px;*/
}
.home-wave {
  background-image: url('./img/eureka_galaxy_e.jpg');
  background-size: cover;
  background-position: top;
  position: absolute;
  top: 0;
  height: 1250px;
}
.home-style {
  /*background-image: url("./img/idea3_b.png");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;*/
  z-index: 10;
}
.home-height {
  height: 500px;
}
.home-section {
  padding-bottom: 50px;
}
.eureka-img {
  width: 90%;
  height: auto;
}
.dotsgroup-style {
  width: 50%;
  margin: 25px 0;
}
.section {
  position: relative;
  padding: 0px;
}
.section-bg-0 {
  background-image: url('./img/eureka_galaxy_a.png');
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
}
.section-bg-1 {
  background-color: transparent;
  position: "relative";
  z-index: -10;
}
/*.us-background {
  background-image: url(./img/galaxy_c.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
}*/
.section-bg {
  /*background-image: url('./img/us_bg.png');*/
  /*background-image: url('./img/section_bg.png');
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;*/
  padding-bottom: 70px;
}
.section-bg-box {
  /*background-image: url('./img/us_bg.png');*/
  background-image: url('./img/eureka_galaxy_footer.png');
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}
/*.section-bg-clouds {
  background-image: url('./img/us_bg.png');
  background-image: url('./img/nubes.png');
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}*/
.section-a {
  position: relative;
  background-color: transparent;
  padding: 50px;
  
}
.section-a-home {
  padding-top: 80px !important;
}
.section-b {
  position: relative;
  background-color: transparent;
  padding-top: 100px;
  padding-bottom: 50px;
  
}
.section-item {
  position: relative;
  background-color: transparent;
  /*padding-top: 90px;
  padding-bottom: 90px;
  background-image: url('./img/us_item_bg.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;*/
}
.sub-section-a {
  /*background-color: #f3f3f3;
  border-radius: 15px;
  padding-top: 50px;*/
  /*background-color: #f9fafe;*/

  position: relative;
}
.sub-section-b {
  background-color: transparent;
}
.section-text-style {
  padding: 0px 30px 150px 30px !important;
}
.section-title {
  font-size: 35px;
  font-weight: 700;
  line-height: 60px;
  margin-bottom: 18px;
  /*font-family: 'Montserrat', sans-serif;*/
  font-family: 'Roboto Mono', monospace;
  color: #000;
  letter-spacing: 5px;
  text-transform: uppercase;
}
.section-desc {
  font-family: 'Roboto Mono', monospace;
  font-size: 15px;
  font-weight: 400;
  color: #000;
  padding: 0 15%;
}
.service-desc {
  font-family: 'Roboto Mono', monospace;
  font-size: 15px;
  font-weight: 400;
  color: #000;
  padding: 0 10%;
}
.section-us-img {
  width: 100%;
  top: 0
}
.card-container {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: rgba(11, 43, 158, 0.15) 0px 6px 20px -6px;
  padding: 50px;
  text-align: left;
}
.card-container-recommended {
  background-color: #ffec64;
}
.card-recomended {
  font-size: 12px;
  font-weight: 700;
  font-family: 'Roboto Mono', monospace;
  color: #000;
  text-align: center;
}
.card-title {
  font-size: 22px;
  font-weight: 700;
  /*font-family: 'Montserrat', sans-serif;*/
  font-family: 'Roboto Mono', monospace;
  color: #000;
  text-align: center;
}
.card-subtitle {
  font-size: 15px;
  font-weight: 200;
  /*font-family: 'Montserrat', sans-serif;*/
  font-family: 'Roboto Mono', monospace;
  color: #000;
  text-align: center;
}
.card-price {
  font-size: 55px;
  font-weight: 700;
  /*font-family: 'Montserrat', sans-serif;*/
  font-family: 'Roboto Mono', monospace;
  color: #000;
}
.card-period {
  font-size: 15px;
  font-weight: 300;
  /*font-family: 'Montserrat', sans-serif;*/
  font-family: 'Roboto Mono', monospace;
  color: #000;
}
.card-elements {
  font-size: 13px;
  font-weight: 300;
  /*font-family: 'Montserrat', sans-serif;*/
  font-family: 'Roboto Mono', monospace;
  color: #000;
}
.card-done {
  width: 15px;
  height: auto;
}
.card-image {
  width: auto;
  height: auto;
}
.item-height-a {
  height: auto;
}
.item-container {
  background-color: #f5f5f7;
  margin-bottom: 20px;
  border-radius: 15px;
}
.item-content {
  padding: 70px 50px;
}
.item-row {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
.item-title-small {
  font-family: 'Russo One', monospace;
  font-size: 25px;
  font-weight: 400;
  text-align: center;
  line-height: 25px;
}
.item-title {
  font-family: 'Russo One', monospace;
  font-size: 38px;
  font-weight: 400;
  text-align: center;
  line-height: 42px;
}
.item-desc {
  font-family: 'Roboto Mono', monospace;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 18px;
}
.item-img-alt {
  width: 80% !important;
  padding: 15px 0 0 0;
}
.item-img {
  width: 70% !important;
  padding: 0 0 15px 0;
}
/*.item-rrss {
  background-image: url('./img/service-rrss.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}*/
/*.item-design {
  background-image: url('./img/design_bg.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}*/
.contact-container {
  padding: 70px;
  border-radius: 20px;
  /*border: 1px solid #eee;*/
  width: 100%;
  height: 100%;
  background-color: transparent;
  opacity: 1;
}
.contact-text {
  color: #fff; 
  cursor: pointer;
}
.contact-icons {
  color: #fff; 
  font-size: 20px; 
  cursor: pointer;
}
.footer-icons {
  color: #b9b5b5; 
  font-size: 20px; 
  cursor: pointer;
}
.footer {
  background-color: #0a001c;

  font-family: 'Roboto Mono', monospace;
  font-size: 13px;
  font-weight: 400;
  padding: 50px 0;
  color: #b9b5b5;
  text-align: center;
}
.footer-title {
  color: #fff;
  font-weight: 700;
  cursor: pointer;
}
.pre-footer {
  background-image: url('./img/eureka_earth.webp');
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  height: 150px;
}
.footer-logo {
  width: 100px;
  height: auto;
}
.dots {
  height: 75px;
}
.modal-dialog {
  max-width: 50% !important;
}
.carousel-container {
  background-image: url(./img/carousel_back.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 15px;
  padding: 25px;
  width: 100%;
  height: 510px;
}

/***************** FLIP **********************/
.er-container {
  position: relative;
  width: 100%;
  cursor: pointer;
}
.centered {
  width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  border-radius: 25px;
}
.overlay-img {
  border-radius: 25px;
}
/*.overlay-color-redes {
  background-color: #2694b1;
  background-image: url('./img/social-back.jpg');
}*/
.overlay-color-medios {
  background-color: #318e91;
}
.overlay-color-activaciones {
  background-color: #3c8770;
}
.overlay-color-marketing {
  background-color: #48804e;
}
.overlay-color-servicios {
  background-color: #356790;
}
.er-container:hover .overlay {
  opacity: 1;
}
.overlay-text {
  width: 75%;
  color: white;
  font-size: 13px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: left;
  line-height: 18px;
}
.overlay-title {
  width: 100%;
  font-size: 25px;
  line-height: 30px;
}
.text-space {
  height: 15px;
}
.col-services {
  margin: 10px 0 !important;
}
.col-contact {
  margin: 5px 0 !important;
}
.col-md-3 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  display: flex;
}
.col-md-4 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  display: flex;
}
.card {
  box-shadow: rgb(11 43 158 / 15%) 0px 6px 20px -6px;
  border: 0 !important;
  border-radius: 15px !important;
}
.card-title-style {
  text-align: center;
  font-size: 19px !important;
  padding: 10px 20px;
}
.card-img, .card-img-top {
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}
.grow-img {
  -webkit-filter: grayscale(100%) brightness(110%) contrast(1);
  filter: grayscale(100%) brightness(110%) contrast(1);
}
.grow-img:hover{
  -webkit-filter: grayscale(0%) contrast(1);
  filter: grayscale(0%) contrast(1);
}
.grow { 
  transition: all .2s ease-in-out; 
  cursor: pointer;
}
.grow:hover { 
  transform: scale(1.1); 
}
.modal-content {
  background: transparent !important;
  border: 0 !important;
}
.modal-title-style {
  line-height: 45px;
}
.modal-img {
  width: 100%;
  height: auto;
  border-radius: 25px 25px 0 0;
}
.modal-service-header { 
  background: transparent !important;
  border-bottom: 0 !important;
}
.modal-service-header { 
  background: transparent !important;
  border-bottom: 0 !important;
}
.modal-service-body { 
  background-color: #f8f9fc;
  /*background-image: url(./img/social.jpg);
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;*/
  padding: 0px !important;
  border-radius: 25px;
}
.modal-service-body-text { 
  padding: 10px 10px 50px 10px !important;
  border-radius: 25px;
}
.policy-title {
  font-size: 30px;
  font-weight: 700;
  line-height: 35px;
  font-family: 'Roboto Mono', monospace;
  color: #000;
  letter-spacing: 5px;
  text-transform: uppercase;
  padding: 0 50px;
  text-align: center;
}
.policy-desc {
  font-size: 15px;
  font-weight: 300;
  font-family: 'Roboto Mono', monospace;
  color: #000;
  padding: 0 50px;
  text-align: justify;
}
.wa-style {
  z-index: 20 !important;
}

/***************** FLIP **********************/

#ek-navbar{
  -webkit-transition: background-color 300ms linear;
  -moz-transition: background-color 300ms linear;
  -o-transition: background-color 300ms linear;
  -ms-transition: background-color 300ms linear;
  transition: background-color 300ms linear;
}

#bg{
  background: rgb(238,174,202);
  background: linear-gradient(180deg, rgba(238,174,202,0) 0%, rgba(238,238,238,1) 10%, rgba(238,238,238,1) 90%, rgba(148,187,233,0) 100%);
  /*background-color: #fff;
  -webkit-transition: background-color 1000ms ease-out;
  -moz-transition: background-color 1000ms ease-out;
  -o-transition: background-color 1000ms ease-out;
  -ms-transition: background-color 1000ms ease-out;
  transition: background-color 1000ms ease-out;*/
}

.grecaptcha-badge {
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: 4px !important;
  bottom: 2rem !important;
}
.grecaptcha-badge:hover {
  width: 256px !important;
}

@media (max-width: 460px) {
  .modal-dialog {
    max-width: 100% !important;
  }
  .home-title-big {
    line-height: 60px;
    font-size: 60px;
    text-align: center;
  }
  .home-title {
    font-size: 20px;
    text-align: center;
    padding-top: 30px;
  }
  .home-subtitle {
    text-align: center;
    padding: 0 10px;
  }
  .section-title {
    font-size: 30px;
    line-height: 35px;
    text-align: center;
    padding-top: 40px;
  }
  .section-a {
    padding-bottom: 20px;
    
  }
  .section-a-home {
    padding-top: 20px !important;
  }
  .section-desc, .service-desc {
    padding: 0 10px;
  }
  .home-wave {
    height: 700px;
  }
  .home-style {
    position: relative;
    background-color: transparent;
    background-image: url('./img/eureka_galaxy_e.jpg');
    background-size: cover;
    background-position: top;
  }
  .section-text-style {
    padding: 0px 15px 30px 15px !important;
  }
  .col-services {
    margin: 10px 0 !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .col-promos {
    margin: 10px 0 !important;
  }
  .col-contact {
    margin: 5px 0 !important;
  }
  .contact-container {
    padding-left: 10px;
    padding-right: 10px;
  }
  .collapse-custom {
    background-color: #fff !important;
  }
  .nav-link-custom {
    color: #000;
  }
  .nav-icons {
    color: #000;
    top: 0px;
    font-size: 15px;
  }
  .modal-service-body-text { 
    padding: 10px 10px 30px 10px !important;
    border-radius: 25px;
  }
  .col-md-3, .col-md-4 {
    display: block;
  }
  .policy-title {
    padding: 0 15px;
  }
  .policy-desc {
    padding: 0 15px;
  }
}
